<template>
  <div v-if="!loading">
    <v-container class="pa-0" fluid>
      <v-simple-table
        fixed-header
        class="text-center"
        style="margin-bottom: 40px;"
      >
        <thead>
          <th class="text-center" v-for="(param, index) in schema" :key="index">
            {{ param.label }}
          </th>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td class="text-center" v-for="(param, ind) in schema" :key="'td'+ind">
              {{ getValue(param, item) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
    <v-pagination
      class="list-pagination"
      v-model="page"
      :length="pages"
      :total-visible="perPage"
      @input="updatePage"
    ></v-pagination>
  </div>
  <div v-else>
    <v-container class="pa-0" fluid>
      <v-skeleton-loader
        ref="skeleton"
        type="list-item-avatar-three-line"
        :boilerplate="false"
        :tile="false"
        class="mx-auto"
        v-for="skeleton in skeletons"
        :key="skeleton"
      ></v-skeleton-loader>
    </v-container>
  </div>
</template>
<script>
import ApiService from "@/util/api.service";

export default {
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    schema: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    console.log(this.baseUrl);

    ApiService({
      url: this.baseUrl,
      method: "get",
    })
      .then((data) => {
        console.log(data);
        this.page = data?.meta?.currentPage || 1;
        this.perPage = data?.meta?.itemsPerPage || 10;
        this.pages = data?.meta?.totalPages || 1;
        this.items = data.items || [];
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getValue(param, item) {
      try {
        switch (param.type) {
          case "text":
            // eslint-disable-next-line no-eval
            return eval(`item.${param.param}`);
          case "date":
            // eslint-disable-next-line no-eval
            return new Date(eval(`item.${param.param}`)).toLocaleString();
          case "custom":
            // eslint-disable-next-line no-eval
            return param.render(eval(`item.${param.param}`));
          default:
            return ''
        }
      } catch (error) {
        console.log(error);
      }
      return "";
    },
    updatePage() {
      // Hacer algo cuando se actualiza la paginación, como cargar datos adicionales
      console.log(`La página actual es: ${this.page}`);
      this.loading = true;
      ApiService({
        url: this.baseUrl,
        method: "get",
        params: {
          page: this.page,
          perPage: this.perPage,
        },
      })
        .then((data) => {
          console.log(data);
          this.page = data?.meta?.currentPage || 1;
          this.perPage = data?.meta?.itemsPerPage || 10;
          this.pages = data?.meta?.totalPages || 1;
          this.items = data.items || [];
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  data() {
    return {
      loading: true,
      page: 1,
      perPage: 12,
      loaders: 10,
      pages: 2,
      items: [],
    };
  },
  watch: {
    search() {
      this.page = 1;
    },
  },
  computed: {
    skeletons() {
      const skeletons = [];
      for (let i = 1; i <= this.loaders; i++) {
        skeletons.push(i);
      }
      return skeletons;
    },
  },
};
</script>
<style type="text/css" scoped>
.child-right {
  background: green;
  height: 100%;
  position: absolute;
  top: 50;
  left: 0;
  right: 0;
}

.v-chip.v-size--default {
  border-radius: 16px;
  font-size: 12px;
  height: unset !important;
}

.v-list-item__action {
  margin-right: unset !important;
}

.v-chip {
  margin-right: 5px;
}

.v-list-item__subtitle {
  margin-bottom: 3px;
  user-select: text;
  white-space: unset !important;
}

.v-list-item__title {
  user-select: text;
}

.v-list-item__subtitle .v-chip {
  user-select: none;
}

.v-input__slot {
  margin-bottom: unset !important;
}

.v-text-field--solo {
  border-radius: unset;
  margin-bottom: unset !important;
  box-shadow: 0 1px 1px -2px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;
}

.list-pagination {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.no-results .nonono {
  color: rgba(0, 0, 0, 0.2) !important;
}

table {
  margin-bottom: 40px;
}
</style>
