export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: "Nombre",
    type: String,
  },
  identifier: {
    label: "Identificador",
    type: String,
  },
  job: {
    label: "Puesto",
    type: String,
  },
  disabled: {
    label: "Desactivado",
    type: Boolean,
  },
  phoneNumber: {
    label: "Telefono",
    type: String,
    min: 10
  },
  type: {
    label: "Tipo",
    type: String,
    options: [{
      id: 'telegram',
      name: 'Telegram'
    }, {
      id: 'whatsapp',
      name: 'Whatsapp'
    }, {
      id: 'cuip',
      name: 'CUIP'
    }]
  },
  attributes: {
    label: "Atributos",
    type: Array,
    title: (item) => item.name
  },
  "attributes.$": {
    type: {
      name: {
        label: "Nombre",
        type: String,
      },
      value: {
        label: "Value",
        type: String,
      },
    },
  },
  createdAt: {
    label: "Registrado",
    type: Date,
    visibility: false,
    optional: true,
  },
  lastUpdate: {
    label: "Ultima Actualizacion",
    type: Date,
    visibility: false,
    optional: true,
  },
  version: {
    label: "Version",
    type: Number,
    visibility: false,
    optional: true,
  },
};
