<template>
  <v-card class="mx-auto elevation-0" height="100%">
    <v-toolbar class="kalan-titles elevation-0" color="primary--text">
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px">
        <v-text-field flat label="Buscar" hide-details v-model="search" append-icon="mdi-magnify">
        </v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list
      :loading="loading"
      :getTitle="getTitle"
      :getSubTitle="getSubTitle"
      :getExtra="getExtra"
      :items="items"
      :search="search"
      :actions="listActions"
    >
    </kalan-list>
  </v-card>
</template>

<script>
import ApiService from "@/util/api.service";
import Clients from "@/models/clients.model";
import Crud from "@/util/crud.service";
import Dialog from "@/components/dialog";
import Form from "@/components/form";
import Paginate from "@/components/paginate/Paginate.vue";
import moment from 'moment';

export default {
  props: {
    model: {
      default: "clients",
    },
  },
  components: {
    "kalan-list": () => import("@/components/modulo/List.vue"),
    "actions-button": () => import("@/components/modulo/Actions.vue"),
  },
  data() {
    return {
      items: [],
      groups: {},
      loading: true,
      search: "",
      clientsModel: { ...Clients },
      attributeFields: [],
      actions: [
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
          icon: "mdi-plus",
          color: "secondary",
          action: (item) => {
            Crud.create({
              item,
              schema: this.clientsModel,
              model: this.model,
              preSave: this.preSave,
              url: "/client",
            }).then((savedItem) => {
              ApiService({
                url: `/user/clients/addDefault/${savedItem.id}/${savedItem.type}`,
                method: "post",
              })
                .catch((err) => {
                  console.error({ err });
                })
                .finally(() => {
                  this.load();
                });
            });
          },
        },
      ],
      listActions: [
        {
          title: "Clases Base",
          help: "Clases Base",
          color: "orange",
          action: async (item) => {
            window.VMA.loading(true);
            try {
              const baseClasses = await ApiService({
                url: "/modules/base-class?all=true",
                method: "get",
              });
              const client = await ApiService({
                url: `/client/identifier/${item.identifier}`,
                method: "get",
              });
              const form = new Form({
                schema: baseClasses.reduce(
                  (acc, bc) => ({
                    ...acc,
                    [bc.id]: {
                      label: bc.name,
                      type: Boolean,
                      changeOnLoad: false,
                      onChange: async (val, fields) => {
                        fields[bc.id].loading = true;
                        if (val) {
                          ApiService({
                            url: `/client/${item.id}/baseclass/add/${bc.id}`,
                            method: "post",
                          })
                            .catch((err) => {
                              fields[bc.id].setValueWithoutTriggers(!val);
                              window.VMA.$emit("SHOW_SNACKBAR", {
                                text: `Error al agregar clase base "${bc.name}"`,
                                color: "error",
                              });
                              console.error({ err });
                            })
                            .finally(() => {
                              fields[bc.id].loading = false;
                            });
                        } else {
                          ApiService({
                            url: `/client/${item.id}/baseclass/remove/${bc.id}`,
                            method: "delete",
                          })
                            .catch((err) => {
                              fields[bc.id].setValueWithoutTriggers(!val);
                              window.VMA.$emit("SHOW_SNACKBAR", {
                                text: `Error al eliminar clase base "${bc.name}"`,
                                color: "error",
                              });
                              console.error({ err });
                            })
                            .finally(() => {
                              fields[bc.id].loading = false;
                            });
                        }
                      },
                    },
                  }),
                  {}
                ),
                item: (client.baseClasses || []).reduce(
                  (acc, bc) => ({
                    ...acc,
                    [bc.id]: true,
                  }),
                  {}
                ),
              });
              const dialog = new Dialog({
                title: "Clases Base",
                subtitle: item.name,
              });
              dialog.open();
              dialog.append(form);
            } catch (err) {
              // ok
              console.error(err);
              window.VMA.showError({ title: "Error al cargar las Clases Base" });
            } finally {
              window.VMA.loading(false);
            }
          },
        },
        {
          title: "Tipos Base",
          help: "Tipos Base",
          color: "orange",
          action: async (item) => {
            window.VMA.loading(true);
            try {
              const baseTypes = await ApiService({
                url: "/modules/base-type?all=true",
                method: "get",
              });
              const client = await ApiService({
                url: `/client/identifier/${item.identifier}`,
                method: "get",
              });
              const form = new Form({
                schema: baseTypes.reduce(
                  (acc, bt) => ({
                    ...acc,
                    [bt.id]: {
                      label: bt.name,
                      type: Boolean,
                      changeOnLoad: false,
                      onChange: async (val, fields) => {
                        fields[bt.id].loading = true;
                        if (val) {
                          ApiService({
                            url: `/client/${item.id}/basetype/add/${bt.id}`,
                            method: "post",
                          })
                            .catch((err) => {
                              fields[bt.id].setValueWithoutTriggers(!val);
                              window.VMA.$emit("SHOW_SNACKBAR", {
                                text: `Error al agregar tipo base "${bt.name}"`,
                                color: "error",
                              });
                              console.error({ err });
                            })
                            .finally(() => {
                              fields[bt.id].loading = false;
                            });
                        } else {
                          ApiService({
                            url: `/client/${item.id}/basetype/remove/${bt.id}`,
                            method: "delete",
                          })
                            .catch((err) => {
                              fields[bt.id].setValueWithoutTriggers(!val);
                              window.VMA.$emit("SHOW_SNACKBAR", {
                                text: `Error al eliminar tipo base "${bt.name}"`,
                                color: "error",
                              });
                              console.error({ err });
                            })
                            .finally(() => {
                              fields[bt.id].loading = false;
                            });
                        }
                      },
                    },
                  }),
                  {}
                ),
                item: (client.baseTypes || []).reduce(
                  (acc, bt) => ({
                    ...acc,
                    [bt.id]: true,
                  }),
                  {}
                ),
              });
              const dialog = new Dialog({
                title: "Tipos Base",
                subtitle: item.name,
              });
              dialog.open();
              dialog.append(form);
            } catch (err) {
              // ok
              console.error(err);
              window.VMA.showError({ title: "Error al cargar los Tipos de Base" });
            } finally {
              window.VMA.loading(false);
            }
          },
        },
        {
          title: "Tipos de Objeto",
          help: "Tipos de Objeto",
          color: "orange",
          action: async (item) => {
            window.VMA.loading(true);
            try {
              const objectTypes = await ApiService({
                url: "/modules/object-type?all=true",
                method: "get",
              });
              const client = await ApiService({
                url: `/client/identifier/${item.identifier}`,
                method: "get",
              });
              const form = new Form({
                schema: objectTypes.reduce(
                  (acc, ot) => ({
                    ...acc,
                    [ot.id]: {
                      label: ot.name,
                      type: Boolean,
                      changeOnLoad: false,
                      onChange: async (val, fields) => {
                        fields[ot.id].loading = true;
                        if (val) {
                          ApiService({
                            url: `/client/${item.id}/objecttype/add/${ot.id}`,
                            method: "post",
                          })
                            .catch((err) => {
                              fields[ot.id].setValueWithoutTriggers(!val);
                              window.VMA.$emit("SHOW_SNACKBAR", {
                                text: `Error al agregar tipo de objeto "${ot.name}"`,
                                color: "error",
                              });
                              console.error({ err });
                            })
                            .finally(() => {
                              fields[ot.id].loading = false;
                            });
                        } else {
                          ApiService({
                            url: `/client/${item.id}/objecttype/remove/${ot.id}`,
                            method: "delete",
                          })
                            .catch((err) => {
                              fields[ot.id].setValueWithoutTriggers(!val);
                              window.VMA.$emit("SHOW_SNACKBAR", {
                                text: `Error al eliminar tipo de objeto "${ot.name}"`,
                                color: "error",
                              });
                              console.error({ err });
                            })
                            .finally(() => {
                              fields[ot.id].loading = false;
                            });
                        }
                      },
                    },
                  }),
                  {}
                ),
                item: (client.objectTypes || []).reduce(
                  (acc, ot) => ({
                    ...acc,
                    [ot.id]: true,
                  }),
                  {}
                ),
              });
              const dialog = new Dialog({
                title: "Tipos de Objeto",
                subtitle: item.name,
              });
              dialog.open();
              dialog.append(form);
            } catch (err) {
              // ok
              console.error({ err });
              window.VMA.showError({ title: "Error al cargar los Tipos de Objeto" });
            } finally {
              window.VMA.loading(false);
            }
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
          icon: "mdi-pencil",
          color: "accent",
          action: (item) => {
            Crud.update({
              item,
              schema: this.clientsModel,
              model: this.model,
              title: this.getTitle(item),
              preSave: this.preSave,
              url: "/client",
            }).then(this.load);
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
          icon: "mdi-eye",
          color: "secondary",
          action: (item) => {
            Crud.read({
              item,
              schema: this.clientsModel,
              model: this.model,
              title: this.getTitle(item),
            });
          },
        },
        {
          help: "Consultas Realizadas",
          icon: "mdi-history",
          color: "black",
          action: (item) => {
            const dialog = new Dialog({
              title: "Consultas Realizadas",
              subtitle: item.name,
            });
            dialog.open();
            dialog.appendPage(Paginate, {
              baseUrl: `/client/history/${item.id}`,
              schema: [
                {
                  param: "createdAt",
                  label: "Fecha",
                  type: "custom",
                  render: (it) => {
                    console.log(it)
                    return moment(it).format("DD/MM/YYYY HH:mm");
                  }
                },
                {
                  param: "baseType.name",
                  label: "Tipo Base",
                  type: "text",
                },
                {
                  param: "parameters",
                  label: "Consulta",
                  type: "custom",
                  render: (it) => {
                    const consulta = it.reduce((acc, para) => acc + para.value + " ", "").trim();
                    return consulta;
                  },
                },
              ],
            });

            // TO DO: Cambiar por el id del cliente
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
          icon: "mdi-delete",
          color: "red",
          action: (item) => {
            Crud.delete({
              id: item.id,
              model: this.model,
              title: this.getTitle(item),
              url: "/client",
            }).then(this.load);
          },
        },
      ],
    };
  },
  mounted() {
    this.loadObjectTypes().then(this.load);
    // this.load();
  },
  methods: {
    getTitle: (item) => `${item.name}`,
    getSubTitle(item) {
      const name = "Desconocido";
      return `${item.job || name} (${item.identifier || name}) `;
    },
    getExtra: () => null,
    preSave(item) {
      const it = Object.assign(item, {});
      this.attributeFields.forEach((field) => {
        it.attributes.push({
          name: field.replace("_attribute_", ""),
          value: it[field],
        });
        it.attributes[field] = it[field];
        delete it[field];
      });
      return it;
    },
    load() {
      this.loading = true;
      ApiService({
        url: "/client?all=true",
        method: "get",
      })
        .then((resp) => {
          this.items = resp.map((it) => {
            this.attributeFields.forEach((field) => {
              const attributeIndex = it.attributes.findIndex(
                (a) => a.name === field.replace("_attribute_", "")
              );
              if (attributeIndex !== -1) {
                it[field] = it.attributes[attributeIndex].value;
                it.attributes.splice(attributeIndex, 1);
              }
            });
            return it;
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async loadObjectTypes() {
      return ApiService({
        url: "/modules/object-type?all=true",
        method: "get",
      })
        .then((resp) => {
          const temp = this.clientsModel.attributes;
          delete this.clientsModel.attributes;
          // if (!this.clientsModel.attributes_separator) {
          //   this.clientsModel.attributes_separator = {
          //     label: 'Twitter',
          //     type: String,
          //     separator: true
          //   }
          // }
          resp.forEach((item) => {
            if (item.parameters) {
              item.parameters.forEach((param) => {
                const attributeName = "_attribute_" + param.name;
                if (param.value[0] === ":" && !this.clientsModel[attributeName]) {
                  this.attributeFields.push(attributeName);
                  this.clientsModel[attributeName] = {
                    label: param.name,
                    type: String,
                    optional: true,
                  };
                }
              });
            }
          });
          this.clientsModel.attributes = temp;
        })
        .catch(() => {});
    },
  },
};
</script>
